// A button that asks the user to sign a message with their wallet and keep the signature in a variable
import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { getSigner, getInputMessage, getInputEnvironment } from '../utils'
import { MinimalAccount } from './MinimalAccount'
import './LinkWalletButton.css'


const requestAddWalletToProfile = async (message: string, signature: string, address: string) => {
    const response = await fetch("https://plfb.troverse.io/api/add-wallet-to-profile", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br'
        },
        body: JSON.stringify({
            FunctionArgument: {
                message,
                signature,
                address,
                ...(getInputEnvironment() === 'dev' && {dev: 1})
            }
        })
    })
    
    return {
        status: response.status,
        text: await response.text()
    }
}

interface Props {
    isDone: boolean
    setDone: (done: boolean) => void
    
    linking: number
    setLinking: (linking: number) => void
}

export const LinkWalletButton: React.FC<Props> = ({ isDone, setDone, linking, setLinking }) => {
    const { account, provider } = useWeb3React()
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

    const linkWallet = async () => {
        setLinking(1)
        try {
            if (!account) throw new Error('No account found')
            if (!provider) throw new Error('No library found')

            const signer = getSigner(provider, account)
            const message = getInputMessage()
            const signature = await signer.signMessage(message)

            if (!signature) throw new Error('No signature found')
            setLinking(2)
            
            const response = await requestAddWalletToProfile(message, signature, account)
            if (response.status > 299) throw new Error(response.status + ' - ' + response.text)
            setDone(true)
        } catch (e: any) {
            setErrorMessage(e?.message || 'Unknown error')
            console.error(e)
        }
        
        setLinking(0)
    }

    return (
        <>
        {!isDone && !linking && !errorMessage && <button className='action-btn' onClick={linkWallet}>Link Wallet</button>}
        {linking === 1 && <div className='linking-text'>Please sign the message.</div>}
        {linking === 2 && <div className='linking-text'>Linking...</div>}
        {errorMessage && <div className='error-text'>ERROR: {errorMessage}</div>}
        {isDone && <div className="done">
            Congrats!<br/><br/><br/>
            Your wallet <span className='wallet-block'><MinimalAccount /></span> has been linked successfully.<br/>
            You may now close this page.
        </div>}
        </>
    )
}
