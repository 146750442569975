// Connect or Disconnect button
import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { ConnectModal } from './ConnectModal'
import { MinimalAccount } from './MinimalAccount'
import { LinkWalletButton } from './LinkWalletButton'
import './ConnectButton.css';

interface Props {
    isDone: boolean
    setDone: (done: boolean) => void

    linking: number
    setLinking: (linking: number) => void
}

export const ConnectButton: React.FC<Props> = ({ isDone, setDone, linking, setLinking }) => {
    const [showModal, setShowModal] = React.useState(false)
    const { connector, isActive } = useWeb3React()
    
    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const disconnect = () => {
        connector.deactivate ? connector.deactivate() : connector.resetState()
    }
    
    return (
        <>
        {isActive ? (
            <>
                {!isDone && <div className="wallet-box"><MinimalAccount /></div>}
                {!isDone && !linking && <button className="action-btn disconnect-btn" onClick={() => disconnect()}>Disconnect</button>}
                {<br/>}
                <LinkWalletButton isDone={isDone} setDone={setDone} linking={linking} setLinking={setLinking} />
            </>
        ) : (
            <button className="action-btn connect-btn" onClick={toggleModal}>Connect Wallet</button>
        )}
        {showModal && <ConnectModal active={isActive} toggleModal={toggleModal} />}
        </>
    )
}