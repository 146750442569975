import React, { useEffect } from 'react'
import { metaMask, coinbaseWallet, walletConnectV2 } from '../connectors'

import './ConnectModal.css';
import metamaskLogo from '../assets/images/metamask-icon.svg'
import coinbaseLogo from '../assets/images/coinbase-icon.svg'
import walletconnectLogo from '../assets/images/wallet-connect-icon.svg'

interface Props {
  active: boolean
  toggleModal: () => void
}

export const ConnectModal: React.FC<Props> = ({ active, toggleModal }) => {

  const connectMetamask = () => {
    metaMask.activate()
  }

  const connectCoinbaseWallet = () => {
    coinbaseWallet.activate()
  }

  const connectWalletConnect = () => {
    walletConnectV2.activate()
  }

  useEffect(() => {
    coinbaseWallet.connectEagerly()
    walletConnectV2.connectEagerly()
  }, [])

  useEffect(() => {
    if (active) {
      toggleModal()
    }
  }, [active])

  return (
    <div className="modal gradient-holder">
      <h3>Connect your wallet</h3>
      <button className="provider-btn gradient-holder" onClick={connectMetamask}>
        <img src={metamaskLogo} />
        <div className="btn-text">MetaMask</div>
      </button>
      <button className="provider-btn gradient-holder" onClick={connectCoinbaseWallet}>
        <img src={coinbaseLogo} />
        <div className="btn-text">Coinbase Wallet</div>
      </button>
      <button className="provider-btn gradient-holder" onClick={connectWalletConnect}>
        <img src={walletconnectLogo} />
        <div className="btn-text">WalletConnect</div>
      </button>
      <button className="close-btn" onClick={toggleModal}>&times;</button>
    </div>
  )
}
