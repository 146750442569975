import { initializeConnector } from '@web3-react/core'
import { CoinbaseWallet } from '@web3-react/coinbase-wallet'
import { MetaMask } from '@web3-react/metamask'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'
import { Web3ReactHooks } from '@web3-react/core'

const supportedChainIds = [1, 5, 137, 80001]
const infuraLink = "https://mainnet.infura.io/v3/885d147049ff4ba6ba7dd8bf75aa6931"

export const [metaMask, metaMask_hooks] = initializeConnector<MetaMask>((actions) => new MetaMask({actions}))

export const [coinbaseWallet, coinbaseWallet_hooks] = initializeConnector<CoinbaseWallet>(
    (actions) => new CoinbaseWallet({
        actions,
        options: {
            appName: 'Troverse Wallet Connection',
            url: infuraLink
        }
    })
)

export const [walletConnectV2, walletConnectV2_hooks] = initializeConnector<WalletConnectV2>(
    (actions) => new WalletConnectV2({
        actions,
        options: {
            projectId: 'abdc04c05b0244bbe094e4a0f89646cc',
            chains: supportedChainIds,
            showQrModal: true,
        },
    })
)

export function getConnectors(): [MetaMask | CoinbaseWallet | WalletConnectV2, Web3ReactHooks][] {
    return [
        [metaMask, metaMask_hooks],
        [coinbaseWallet, coinbaseWallet_hooks],
        [walletConnectV2, walletConnectV2_hooks]
    ]
}
