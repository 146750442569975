import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { Web3ReactProviderWrapper } from './Web3Provider'
import { Buffer } from 'buffer'

global.Buffer = Buffer
ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProviderWrapper>
      <App />
    </Web3ReactProviderWrapper>
  </React.StrictMode>,
  document.getElementById('root')
)