import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { getConnectors } from './connectors'

interface Props {
    children: React.ReactNode
}

export const Web3ReactProviderWrapper: React.FC<Props> = ({ children }) => {
    return <Web3ReactProvider connectors={getConnectors()}>{children}</Web3ReactProvider>
}
