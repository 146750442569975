import { Web3Provider } from '@ethersproject/providers'

export const getSigner = (provider: Web3Provider, account: string | null | undefined) => {
    return provider.getSigner(account ?? undefined).connectUnchecked()
}

export const getInputMessage = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const message = urlParams.get('message')

    if (!message)
        throw new Error('Broken link, try again from the Troverse launcher')

    return message.replace(/ /g, '+')
}

export const getInputEnvironment = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const environment = urlParams.get('env') ?? 'prod'

    if (environment === 'dev' || environment === 'prod')
        return environment

    throw new Error('Broken environment link!')
}
