import React, { useEffect } from 'react'
import './App.css'
import { getInputMessage, getInputEnvironment } from './utils'
import { ConnectButton } from './components/ConnectButton'

import logoNamed from './assets/images/logo-named.png'


function App() {
  const [isDone, setDone] = React.useState<boolean>(false)
  const [linking, setLinking] = React.useState<number>(0)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  useEffect(() => {
    try {
      getInputEnvironment()
      getInputMessage()
    } catch (e: any) {
      setErrorMessage(e?.message || 'Unknown error')
      console.error(e)
    }
  }, [])
  
  return (
    <div className="App">
      <header className="App-header">
        {<img src={logoNamed} className="App-logo" alt="logo" />}
        {!isDone && !errorMessage && <p>Link your wallet with your profile</p>}
        {!errorMessage && <ConnectButton isDone={isDone} setDone={setDone} linking={linking} setLinking={setLinking} />}
        {errorMessage && <div className='error-text'>ERROR: {errorMessage}</div>}
      </header>
    </div>
  )
}

export default App